import React from "react";
import Form from "library/basic/forms/Form";
import useFormInit from "./useFormInit";

/**
 * Common template for public pages
 *
 * @param formAction
 * @param formDefinitionAction
 * @param mainForm
 * @param isRequestFailed - Form submit failed with an error
 * @param title - Ex: Forgotten Your Password
 * @param subTitle - Ex: We will send you an email with instructions on how to reset your password.
 * @param errorMessage - Ex: We could not send forgot password request. Please try again.
 * @returns {*}
 * @constructor
 */
const PublicForm = ({formAction, formDefinitionAction, mainForm, title, subTitle, isRequestFailed, errorMessage}) => {
        const {fetchFormData} = useFormInit({formAction});

        const currentYear = new Date().getFullYear();

        return (
            <>
                <div className="page-container login-container">
                    <div className="page-content">
                        <div className="content-wrapper">
                            <div className="content">
                                <div className="panel panel-body login-form">
                                    <div className="text-center">
                                        <div
                                            className="display-block text-slate-300 border-bottom border-color-light-grey pb-10 mb-10">
                                            <img src="/logo.png"/></div>
                                        <h5 className="content-group">
                                            {title}
                                            <small className="display-block">{subTitle}</small>
                                        </h5>
                                    </div>
                                    {isRequestFailed && <div className="alert alert-danger alert-styled-left alert-bordered"
                                                             style={{fontWeight: 'normal'}}>
                                        <span className="text-semibold">Sorry! </span>
                                        {errorMessage}
                                    </div>}

                                    <Form
                                        id="form-data"
                                        action={formAction}
                                        formClass='form_data generic_form'
                                        jsonArray={mainForm?.parent}
                                        encType='application/x-www-form-urlencoded'
                                        jsonLoadAction={formDefinitionAction}
                                        jsonLoadActionHandler={fetchFormData}
                                    />
                                </div>

                                <div class="footer text-muted">
                                    &copy; {currentYear}. <a href="https://app.f1fantasytipping.com.au/">F1 Fantasy Tipping</a>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </>
        );
    }
;

export default PublicForm;
