import {
    React,
    useActionUrl,
    useEffect,
    useLocation,
    queryString,
    useState
} from 'library/base/baseContainerImports';
import {getInfo} from "../../../../service/request-handler";

const Container = () => {

    const location = useLocation();
    const {hashcode} = queryString.parse(location.search);
    const [hashResult, setHashResult] = useState(null);

    const {getFormActions} = useActionUrl();
    const {formAction} = getFormActions(null, {hashcode});

    useEffect( () => {
        getInfo(formAction + '&check=result', checkFormResult, () => console.error('Something went wrong'));
    }, []);


    const checkFormResult = (data) => {
        if (data === 0){
            setHashResult('The activation link you have clicked is invalid. Please contact F1 Fantasy Tipping.');
        }else if(data === 2){
            setHashResult('Your activation link has timed out. Please contact F1 Fantasy Tipping.');
        }else{
            setHashResult(1);
            window.location = '/admin/login?activation=1';
        }
    };

    return (
        <div>
            <div className="page-container login-container">
                <div className="page-content">
                    <div className="content-wrapper">
                        <div className="content">
                            <div className="panel panel-body login-form">
                                <div className="text-center">
                                    <div className="activate_message">
                                        <div className="nNote alert alert-danger alert-styled-left alert-bordered no-margin-bottom" >
                                            <p><strong dangerouslySetInnerHTML={{ __html: hashResult }}></strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Container;
